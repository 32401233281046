import { FC } from "react";

import { HStack } from "@chakra-ui/react";

import { PriceTag } from "@components/shoppingCart/PriceTag";
import { IProduct } from "@features/types";

interface IProductPriceTagProps {
  product: IProduct;
}

const ProductPriceTag: FC<IProductPriceTagProps> = ({ product }) => {
  const { price, originalPrice } = product;

  return (
    <HStack
      data-test-id={`productPriceTag_${product.name}`}
      spacing="2"
      color="blackAlpha.600"
      fontSize="textSize.regular"
    >
      <PriceTag
        currency={price.currency}
        originalPrice={originalPrice?.current}
        currentPrice={price.current}
        salePriceDirection="row"
        rootProps={{
          align: "center",
          spacing: "2",
        }}
        priceProps={{
          color: "gray.400",
          fontWeight: "medium",
          fontSize: "textSize.regular",
        }}
        salePriceProps={{
          color: "blackAlpha.700",
          fontWeight: "medium",
        }}
      />
    </HStack>
  );
};

export default ProductPriceTag;
